<template>
  <v-btn class="s-button" v-bind="$attrs" v-on="$listeners"><slot /></v-btn>
</template>

<script>
export default {
  name: 'SButton',
  inheritAttrs: false,
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.s-button {
  text-transform: unset;
  font-weight: $font-medium;
  font-size: $text-md;

  min-height: 48px;
  border-radius: $default-radius;
}

.s-button:disabled {
  opacity: 0.5;
  background: transparent !important;
}

.s-button.v-size--small {
  height: 38px !important;
  min-height: 38px !important;
}

.s-button.v-size--x-small {
  height: 30px !important;
  min-height: 30px !important;
}

.success,
.green {
  color: white;
}
</style>
